$(function() {

  $('#video-no-use-with-js').hide();
  $('#video-use-with-js').show();
  
  var $filters = $('.js-videos-filters');
  var $body = $('body');
  $('.js-videos-filters-opener').on('click', function(e) {
    e.preventDefault();

    if ($filters.is(':visible')) {
      if (!$('.js-videos-filters-close').is(':visible')) return;
      $filters.hide();
      $body.removeClass('no-scroll');
    }
    else {
      $filters.show();
      $body.addClass('no-scroll');
    }
  });

  $('.js-videos-filters-close').on('click', function(e) {
    e.preventDefault();
    $('.js-videos-filters-opener').trigger('click');
  });

  var form_submit = function() {
    $('#js-video-loader').show();
    $('#js-video-list, #js-video-pagination').html('');

    var $filters = $('#filters');
    $.get($filters.data('action'), $filters.serializeArray(), function(data, textStatus, jqXHR) {
      var $document = $(data);
      var $js_pagination = $('#js-video-pagination');
      $('#js-video-loaded').html($document.find('#js-video-loaded').html());
      $js_pagination.html($document.find('#js-video-pagination').html());

      if ($js_pagination.find('.c-pagination__item').length) {
        $js_pagination.addClass('c-pagination--with-items');
      }
      else {
        $js_pagination.removeClass('c-pagination--with-items');
      }
    });
  }

  $('#filters').on('reset', function(e) {
    setTimeout(form_submit, 100);
  });

  $('#filters').on('submit', function(e) {
    e.preventDefault();
    form_submit();
  });

  $('#filters').find('input, select').on('change', function() {
    $('#filters').trigger('submit');
  });
  
});