$(function() {
  var $popin = $('.js-video-share-popin');
  var $body = $('body');

  $('.js-video-share').on('click', function(e) {
    e.preventDefault();

    if ($popin.is(':visible')) {
      if (!$('.js-video-share-close').is(':visible')) return;
      $popin.hide();
      $body.removeClass('no-scroll');

      $('.acf-success-message').hide();
    }
    else {
      $popin.show();
      $body.addClass('no-scroll');
    }
  });
  
  if ($('.js-video-share').data('open')) {
    $('.js-video-share:eq(0)').trigger('click');
  }

  $('.js-video-share-close').on('click', function(e) {
    e.preventDefault();
    $('.js-video-share:eq(0)').trigger('click');
  });

  $('.js-copy-link').on('click', function(e) {
    e.preventDefault();
  });
  var clipboard = new ClipboardJS('.js-copy-link');
  var successTimeout = null;
  clipboard.on('success', function(e) {
    $('.js-copy-success').show();
    if (successTimeout) clearTimeout(successTimeout);
    successTimeout = setTimeout(function() {
      $('.js-copy-success').hide();
    }, 5000);
  });
});