$(document).ready(function () {
  $('.js-scroll-to').click(function (e) {
    e.preventDefault();

    jsScrollTo($(this).data('target'));

    return false;
  });
});

function jsScrollTo(target) {
  var speed = 750,
      menuHeight,
      windowWidth;

  function getWindowWith() {
    windowWidth = $(window).width();
  }

  getWindowWith();

  $(window).resize(function () {
    getWindowWith();
  });

  if (windowWidth >= 720) {
    menuHeight = 0;
  }
  else {
    menuHeight = 0;
  }

  if ($('#wpadminbar').length > 0) {
    // menuHeight += 50;
  }

  $('html, body').animate({
    scrollTop: $(target).offset().top - menuHeight
  }, speed);
}
